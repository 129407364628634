<template>
  <div>
    <!--begin::dashboard-->
    <status-widgets />
    <!--end::dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

import StatusWidgets from "@/view/pages/addresses/widgets/StatusWidgets";

export default {
  name: "dashboard",
  components: {
    StatusWidgets
  },
  data() {
    return {
      status: [],
      selectedStatus: []
    };
  },
  methods: {
    loadStatus() {
      ApiService.get("status/all").then(response => {
        this.status = response.data;
        for (let i = 0; i < this.status.length; i++) {
          this.selectedStatus.push(this.status[i].value); // toggle all status
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "tktVivax", subTitle: "Vertriebssteuerungstool" }
    ]);

    this.loadStatus();
  }
};
</script>
