<template>
  <div>
    <h3>GNV Angebotsstatus</h3>
    <div class="row mt-7">
      <div class="col-xl-3 col-md-6 col-12">
        <open-offers-widget
          :value="counts.status_open"
          :is-loading="isLoading"
          :is-error="isError"
        />
      </div>
      <div class="col-xl-3 col-md-6 col-12">
        <sent-offers-widget
          :value="counts.status_opened"
          :is-loading="isLoading"
          :is-error="isError"
        />
      </div>
      <div class="col-xl-3 col-md-6 col-12">
        <accepted-offers-widget
          :value="counts.status_accepted"
          :is-loading="isLoading"
          :is-error="isError"
        />
      </div>
      <div class="col-xl-3 col-md-6 col-12">
        <rejected-offers-widget
          :value="counts.status_rejected"
          :is-loading="isLoading"
          :is-error="isError"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OpenOffersWidget from "@/view/pages/addresses/widgets/OpenOffersWidget";
import SentOffersWidget from "@/view/pages/addresses/widgets/SentOffersWidget";
import AcceptedOffersWidget from "@/view/pages/addresses/widgets/AcceptedOffersWidget";
import RejectedOffersWidget from "@/view/pages/addresses/widgets/RejectedOffersWidget";
import ApiService from "@/core/services/api.service";

export default {
  name: "StatusWidgets",
  components: {
    OpenOffersWidget,
    SentOffersWidget,
    AcceptedOffersWidget,
    RejectedOffersWidget
  },
  data() {
    return {
      status: [],
      counts: {},
      isLoading: true,
      isError: false
    };
  },
  methods: {
    getStatus(name) {
      return this.status.find(o => o.name === name);
    }
  },
  mounted() {
    ApiService.get("kpi/status/offer/all")
      .then(response => {
        this.status = response.data.items;

        for (let i = 0; i < this.status.length; i++) {
          let currStatus = this.status[i];

          this.counts[currStatus.name] = currStatus.n;
        }

        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.isError = true;
      });
  }
};
</script>

<style scoped></style>
