<template>
  <BasicStatWidget
    :value="value"
    :label="label"
    :icon-type="iconType"
    :icon-source="iconSource"
    :is-loading="isLoading"
    :is-error="isError"
  ></BasicStatWidget>
</template>

<script>
import BasicStatWidget from "@/view/content/widgets/stats/BasicStatWidget";

export default {
  name: "OpenOffersWidget",
  props: ["value", "isLoading", "isError"],
  data() {
    return {
      label: "Nicht Angeboten",
      iconType: "warning",
      iconSource: "fas fa-exclamation"
    };
  },
  computed: {},
  mounted() {},
  components: {
    BasicStatWidget
  }
};
</script>
