<template>
  <BasicStatWidget
    :value="value"
    :label="label"
    :icon-type="iconType"
    :icon-source="iconSource"
    :is-loading="isLoading"
    :is-error="isError"
  ></BasicStatWidget>
</template>

<script>
import BasicStatWidget from "@/view/content/widgets/stats/BasicStatWidget";

export default {
  name: "RejectedOffersWidget",
  components: {
    BasicStatWidget
  },
  props: ["value", "isLoading", "isError"],
  data() {
    return {
      label: "Abgelehnt",
      iconType: "danger",
      iconSource: "fas fa-times"
    };
  }
};
</script>
